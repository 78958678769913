exports.onClientEntry = () => {
  const script1 = document.createElement("script")
  script1.setAttribute("src", "https://www.googletagmanager.com/gtag/js?id=G-HGJPYK8PJE")
  script1.setAttribute("async", true)
  document.head.appendChild(script1)

  const script2 = document.createElement("script")
  script2.innerHTML = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'G-HGJPYK8PJE');
  `
  document.head.appendChild(script2)
}
